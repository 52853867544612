import { getDomainConfig } from "@onlinesales-ai/domain-config-v2";

const configOverrides = {
  /*
    Development Configs
  */
  "appslocal-dev": {
    name: "hotstar-staging.json",
  },
  "staging-apps": {
    name: "hotstar-staging.json",
  },
  "staging-v3-apps": {
    name: "hotstar-staging.json",
  },
  "staging-v4-apps": {
    name: "hotstar-staging.json",
  },
  "staging-v6-apps": {
    name: "hotstar-staging.json",
  },
  "staging-v7-apps": {
    name: "hotstar-staging.json",
  },
  "staging-v8-apps": {
    name: "hotstar-staging.json",
  },
  "staging-v9-apps": {
    name: "hotstar-staging.json",
  },
  "staging-v10-apps": {
    name: "hotstar-staging.json",
  },
  "osmos-staging-12": {
    name: "hotstar.json",
  },
  "osmos-staging-3": {
    name: "hotstar-prod.json",
  },
  "monetize-staging-10": {
    name: "hotstar-staging.json",
  },
  hotstar: {
    name: "hotstar.json",
  },
  "hotstar-staging": {
    name: "hotstar-staging.json",
  },
  "hotstar-prod": {
    name: "hotstar-prod.json",
  },
  "hotstar-sd": {
    name: "hotstar-sd.json",
  },
  "hotstar-sd-prod": {
    name: "hotstar-sd-prod.json",
  },
  "adsmanager.hotstar.com": {
    name: "hotstar-prod.json",
  },
  "adsmanager-staging.hotstar.com": {
    name: "hotstar.json",
  },
  "reports-adsmanager.hotstar.com": {
    name: "hotstar-sd-prod.json",
  },
  "hotstar-prod-test": {
    name: "hotstar-prod-test.json",
  },
  "publisher-demo": {
    name: "publisher-demo.json",
  },
};

const configs = {
  "hotstar.json": () => import("./configs/hotstar"),
  "hotstar-staging.json": () => import("./configs/hotstar-staging"),
  "hotstar-prod.json": () => import("./configs/hotstar-prod"),
  "hotstar-sd.json": () => import("./configs/hotstar-sd"),
  "hotstar-sd-prod.json": () => import("./configs/hotstar-sd-prod"),
  "hotstar-prod-test.json": () => import("./configs/hotstar-prod-test"),
  "publisher-demo.json": () => import("./configs/publisher-demo"),
  "osmos-staging-3.json": () => import("./configs/hotstar-prod"),
};

export const getDomainConfiguration = ({ clientConfigMapping = {} }) => {
  const domain = window.location.hostname;
  const domainParts = domain.split(".onlinesales.ai");
  const params = new URLSearchParams(window.location.search);
  const configName = params.has("configName") ? params.get("configName") : null;

  return getDomainConfig({
    configIdentifier: configName || domainParts[0],
    defaultConfigName: "base.json",
    configOverrides,
    clientConfigMapping,
    configs,
  });
};
