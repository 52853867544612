import { setCookie, setLSItem, customMergeOS } from "@onlinesales-ai/util-methods-v2";
import { CLIENT_AGGREGATOR_CONFIG, rmnAccountConfigOverride } from "@onlinesales-ai/constants-v2";
import _uniqBy from "lodash/uniqBy";

import Types from "./types";
import DomainConfigTypes from "../domainConfig/types";

const getClientInfo = (clientId, clients = []) => {
  if (Array.isArray(clients)) {
    return clients.find((client) => clientId === client.clientId);
  }

  return null;
};

const reducer = (state, action, initialState = {}) => {
  switch (action.type) {
    case Types.APP_LOG_OUT:
      {
        state = {
          ...initialState,
          agencyId: state.agencyId,
          marketplaceClientId: state.marketplaceClientId,
          agencySettings: state.agencySettings,
          isMobile: state.isMobile,
          deviceType: state.deviceType,
        };
      }
      break;
    case Types.APP_SET_SHOP_INFO:
      {
        state.shopInfo = action.shop
          ? {
              ...action.shop,
            }
          : null;
      }
      break;
    case Types.SET_IS_TOPUP_ENABLED_IN_APPLICATION:
      {
        state = {
          ...state,
          isTopupEnabled: action.isEnabled,
        };
      }
      break;
    case Types.SET_DATA_DELAY_FOR_LIVE_REPORT:
      {
        state = {
          ...state,
          isLiveReportingDataDelayed: action.isLiveReportingDataDelayed,
          maxAvailabilityHourForLiveReportingData: action.maxAvailabilityHourForLiveReportingData,
          clientTimeZoneDiff: action.clientTimeZoneDiff,
          maxAvailibilityClientTZ: action.maxAvailibilityClientTZ,
        };
      }
      break;
    case Types.APP_SET_UA_TOKEN:
      {
        state.UA_TOKEN = action.UA_TOKEN;
      }
      break;

    case Types.APP_SET_USER_INFO:
      {
        state = {
          ...state,
          userInfo: action.userInfo,
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          userInfo: action.userInfo,
          event: "USER_SET",
        });
      }
      break;

    case Types.APP_ADD_NEW_CLIENT:
      {
        let clients = state.clients || [];
        if (action.shop) {
          clients = [...clients, action.shop];
        }

        state = {
          ...state,
          clients,
        };
      }
      break;

    case Types.SET_IS_INTERNAL_USER:
      {
        state = {
          ...state,
          isInternalUser: action.isInternalUser,
          isRealInternalUser: action.isRealInternalUser,
        };
      }
      break;

    case Types.SET_IS_PARTNER_INTERNAL_USER:
      {
        state = {
          ...state,
          isPartnerInternalUser: action.isPartnerInternalUser,
        };
      }
      break;

    case Types.SET_IS_INTERNAL_USER_VIEW_MODE:
      {
        state = {
          ...state,
          isInternalUserViewMode: action.isInternalUserViewMode,
        };
      }
      break;

    case Types.SET_IS_AD_BLOCKER_ENABLED:
      {
        state = {
          ...state,
          isAdBlockerEnabled: action.isAdBlockerEnabled,
        };
      }
      break;

    case Types.SET_MONETIZE_GOALS_FETCH_STATUS:
      {
        state = {
          ...state,
          isMonetizeGoalsFetchInProgress: action.status,
        };
      }
      break;
    case Types.SET_BUSINESS_DETAILS_CHECK_FLAG:
      {
        state = {
          ...state,
          isBusinessDetailsFilled: action.isBusinessDetailsFilled,
        };
      }
      break;
    case Types.APP_SET_CLIENTS:
      {
        if (
          action.isShowAllClientsOption &&
          (action.alwaysShowAllClientOption || action.clients?.length > 1)
        ) {
          action.clients = [
            {
              ...(action.clients?.[0] || {}),
              id: CLIENT_AGGREGATOR_CONFIG.value,
              clientId: CLIENT_AGGREGATOR_CONFIG.value,
              name: action.allClientDisplayName || CLIENT_AGGREGATOR_CONFIG.displayName,
              statusType: "ACTIVE",
            },
            ...action.clients,
          ];
        }
        state = {
          ...state,
          clients: _uniqBy([...(state?.clients || []), ...action.clients], "id"),
        };
      }
      break;
    case Types.APP_SET_OUTLETS:
      {
        state = {
          ...state,
          outlets: action.outlets,
        };
      }
      break;

    case Types.APP_CLIENT_CHANGE:
    case Types.SET_ACTIVE_CLIENT_ID:
      {
        let { clientId, isAllClientsSelected = false, domainConfig = {} } = action || {};
        let clientIds = [];
        const shopInfo = getClientInfo(clientId, state.clients);

        if (clientId === CLIENT_AGGREGATOR_CONFIG.value) {
          isAllClientsSelected = true;
          clientIds = state.clients.map((c) => c.clientId);
          if (!(state.clients?.length > 1)) {
            isAllClientsSelected = false;
          }
        } else {
          clientIds = [clientId];
        }
        // Removing Aggregator values from clientIds
        clientIds = clientIds.filter((id) => {
          return id !== CLIENT_AGGREGATOR_CONFIG.value;
        });
        const exDays = (domainConfig.shopExpiryTimeInHours || 24 * 100) / 24;
        const d = new Date();
        d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
        setCookie("shop", shopInfo, exDays);
        setLSItem("shop_expiry_ts", d);
        state = {
          ...state,
          isBillingStatusFetchInProgress: initialState.isBillingStatusFetchInProgress,
          isBillingNotSubscribed: initialState.isBillingNotSubscribed,
          agreementPropertyData: initialState.agreementPropertyData,
          billingRouteKey: initialState.billingRouteKey,
          pendingOrderDetails: initialState.pendingOrderDetails,
          isMonetizeGoalsFetchInProgress: initialState.isMonetizeGoalsFetchInProgress,
          isAllClientsSelected,
          clientId,
          clientIds,
          shopInfo,
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          clientInfo: shopInfo,
          event: "CLIENT_SET",
        });
      }
      break;

    case Types.SET_CLIENTS_FETCH_STATE:
      {
        state = {
          ...state,
          clientsFetchInProgress: action.fetchState.isLoading,
          clientsFetchError: action.fetchState.error,
        };
      }
      break;

    case Types.SET_IS_ALL_CLIENTS_FETCHED:
      {
        state = {
          ...state,
          isAllClientsFetched: action.fetched,
        };
      }
      break;

    case Types.SET_USER_FETCH_STATE:
      {
        state = {
          ...state,
          userFetchInProgress: action.fetchState.isLoading,
          userFetchError: action.fetchState.error,
        };
      }
      break;

    case DomainConfigTypes.SET_DOMAIN_CONFIG:
      {
        const config = action.config || {};
        state = {
          ...state,
          marketplaceClientId: config.marketplaceClientId,
          agencyId: config.agencyId,
        };

        if (!state.agencySettings) {
          state.agencySettings = customMergeOS(
            {},
            action.config?.agencySettings?.default,
            state.agencySettings || {},
            action.config?.agencySettings?.overrides,
          );
        }
      }
      break;

    case Types.SET_AGENCY_SETTINGS_IN_APP:
      {
        state.agencySettings = customMergeOS(
          {},
          action.config?.agencySettings?.default,
          state.agencySettings || {},
          action.agencySettings || {},
          action.config?.agencySettings?.overrides,
        );
      }
      break;

    case Types.SET_MEDIA_QUERY_DEVICE:
      {
        if (action.isMobile !== state.isMobile || action.deviceType !== state.deviceType) {
          state = {
            ...state,
            isMobile: action.isMobile,
            deviceType: action.deviceType,
          };
        }
      }
      break;
    case Types.APP_PUSH_FILE_DOWNLOAD:
      {
        if (action?.fileDownloadInfo?.type) {
          const fileDownloadStatus = [...(state.fileDownloadStatus || []), action.fileDownloadInfo];
          state = {
            ...state,
            fileDownloadStatus,
          };
        }
      }
      break;
    case Types.APP_DISMISS_FILE_DOWNLOAD:
      {
        const newStatus = [...(state.fileDownloadStatus || [])];
        if (action.index >= 0 && action.index < newStatus.length) {
          newStatus.splice(action.index, 1);
          state = {
            ...state,
            fileDownloadStatus: newStatus,
          };
        }
      }
      break;
    case Types.APP_UPDATE_FILE_DOWNLOAD_STATUS:
      {
        let fileDownloadStatus = state.fileDownloadStatus;
        if (action.fileDetails?.length && fileDownloadStatus?.length) {
          action.fileDetails.forEach((actionFileInfo) => {
            for (let i = 0; i < fileDownloadStatus.length; i++) {
              if (actionFileInfo.type && actionFileInfo.type === fileDownloadStatus[i].type) {
                switch (actionFileInfo.type) {
                  case "BULK_ACTION":
                    {
                      if (
                        actionFileInfo.jobDetails?.id &&
                        actionFileInfo.jobDetails?.id === fileDownloadStatus[i].jobDetails?.id
                      ) {
                        fileDownloadStatus = [...fileDownloadStatus];

                        fileDownloadStatus.splice(i, 1, {
                          ...actionFileInfo,
                          jobDetails: {
                            ...actionFileInfo.jobDetails,
                            ...action.jobDetailsOverrides,
                          },
                        });
                        break;
                      }
                    }
                    break;
                }
              }
            }
          });

          state = {
            ...state,
            fileDownloadStatus,
          };
        }
      }
      break;
    case Types.APP_SET_BILLING_STATUS_FETCH_IN_PROGRESS:
      {
        state = {
          ...state,
          isBillingStatusFetchInProgress: action.isFetchInProgress,
        };
      }
      break;
    case Types.APP_SET_BILLING_SUBSCRIBED_STATUS:
      {
        state = {
          ...state,
          isBillingNotSubscribed: action.isBillingNotSubscribed,
          isClientNotSubscribed: action.isBillingNotSubscribed,
        };
      }
      break;
    case Types.APP_SET_CLIENT_SUBSCRIBED_STATUS:
      {
        state = {
          ...state,
          isClientNotSubscribed: action.isClientNotSubscribed,
        };
      }
      break;
    case Types.APP_SET_BILLING_ROUTE_KEY_TO_USE:
      {
        state = {
          ...state,
          billingRouteKey: action.key,
        };
      }
      break;
    case Types.APP_SET_AGREEMENT_PROPERTY_DATA:
      {
        state = {
          ...state,
          agreementPropertyData: action.data,
        };
      }
      break;
    case Types.SET_STICKY_HEADER:
      {
        {
          state = {
            ...state,
            isShowStickyHeader: action.isShow,
          };
        }
      }
      break;
    case Types.APP_SET_CLIENT_PENDING_ORDERS:
      {
        state = {
          ...state,
          pendingOrderDetails: action.pendingOrderDetails || [],
        };
      }
      break;
    case Types.APP_SET_TAGGING_DATA:
      {
        state = {
          ...state,
          taggingData: action.data || {},
        };
      }
      break;
    case Types.APP_SET_REPORTING_X_DAYS_DATA:
      {
        state = {
          ...state,
          reportingData: {
            ...state.reportingData,
            [action?.key]: action.data,
          },
        };
      }
      break;
    case Types.SET_APP_LEVEL_ACTIONS_DATA:
      {
        state = {
          ...state,
          [action.storeKey]: action.data,
        };
      }
      break;
    case Types.SET_RMN_ACCOUNT_CONFIG:
      {
        const copyData = {
          ...action.data,
        };

        Object.keys(copyData).forEach((key) => {
          copyData[key] = {
            ...rmnAccountConfigOverride?.default,
            ...rmnAccountConfigOverride?.[key],
            ...copyData?.[key],
          };
        });

        state = {
          ...state,
          [action.storeKey]: copyData,
        };
      }
      break;
    case Types.SET_NETWORK_LIST:
      {
        state = {
          ...state,
          networkList: action?.list,
        };
      }
      break;
    case Types.SET_ONBOARDING_DETAILS:
      {
        state = {
          ...state,
          agencySettings: customMergeOS(
            {},
            action.domainConfig?.agencySettings?.default,
            state.agencySettings || {},
            action.data?.agencySettings,
            action.domainConfig?.agencySettings?.overrides,
          ),
        };
      }
      break;
    case Types.SET_APP_LEVEL_DATA_FETCH_STATE:
      {
        state = {
          ...state,
          fetchAppLevelData: action.isLoading,
        };
      }
      break;
    case Types.APP_SET_ENTITY_ID:
      {
        state = {
          ...state,
          clientId: action.entityType === "CLIENT" ? action.entityId : null,
          selectedEntityId: action.entityId,
          selectedEntityType: action.entityType,
        };
      }
      break;
    case Types.SET_DEVICE_THEME:
      {
        state = {
          ...state,
          preferredTheme: action.preferredTheme,
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default reducer;
